import http from "../axios-instance";

class RegMakeService {
  create(model) {
    return http
      .post("/reg-search/vmake", model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  update(vmakeId, name, active) {
    return http
      .put(`/reg-search/vmake/${vmakeId}`, { name, active })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  get(params) {
    return http
      .get("/reg-search/vmake", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  delete(vMakeId) {
    return http
      .delete(`/reg-search/vmake/${vMakeId}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelected(params) {
    return http
      .get(`/reg-search/vmake/selected/${params?.vtype ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new RegMakeService();
